import Cookies from "js-cookie";

const TokenKey = "token";
const UserInfoKey = "userInfo";

export function getToken() {
  // return sessionStorage.getItem(TokenKey);
  return Cookies.get(TokenKey);
}

export function setToken(token) {
  // return sessionStorage.setItem(TokenKey, token);
  return Cookies.set(TokenKey, token);
}

export function removeToken() {
  // sessionStorage.clear();
  Cookies.remove(TokenKey);
  Cookies.remove(UserInfoKey);
}

// export function setUserInfo(userInfo) {
//   // return sessionStorage.setItem(UserInfoKey, JSON.stringify(userInfo));
//   return Cookies.set(UserInfoKey, JSON.stringify(userInfo));
// }

// export function getUserInfo(userInfo) {
//   let data = Cookies.get(UserInfoKey);
//   if (data) {
//     return JSON.parse(data);
//   } else {
//     return data;
//   }
// }

export { TokenKey };
