import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/livePc",
  },
  {
    path: "",
    redirect: "/livePc",
  },
  {
    path: "/liveMobile",
    name: "liveMobile",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/liveMobile.vue"),
  },
  {
    path: "/livePc",
    name: "livePc",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/livePc.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
