// designWidth:设计稿的实际宽度值，需要根据实际设置
// maxWidth:制作稿的最大宽度值，需要根据实际设置
// 这段js的最后面有两个参数记得要设置，一个为设计稿实际宽度，一个为制作稿最大宽度，例如设计稿为750，最大宽度为750，则为(750,750);
(function (designWidth, maxWidth) {
    let doc = document,
      win = window;
    let docEl = doc.documentElement;
    let tid;
    let rootItem, rootStyle;
    function refreshRem() {
      let width = docEl.getBoundingClientRect().width;
      if (!maxWidth) {
        maxWidth = 540;
      }
      if (width > maxWidth) {
        width = maxWidth;
      }
      // 与淘宝做法不同，直接采用简单的rem换算方法1rem=100px
      let rem = width * 100 / designWidth;
      // 兼容UC开始
      rootStyle = "html{font-size:" + rem + 'px !important}';
      rootItem = document.getElementById('rootsize') || document.createElement("style");
      if (!document.getElementById('rootsize')) {
        document.getElementsByTagName("head")[0].appendChild(rootItem);
        rootItem.id = 'rootsize';
      }
      if (rootItem.styleSheet) {
        rootItem.styleSheet.disabled || (rootItem.styleSheet.cssText = rootStyle)
      } else {
        try {
          rootItem.innerHTML = rootStyle
        } catch (f) {
          rootItem.innerText = rootStyle
        }
      }
      // 兼容UC结束
      docEl.style.fontSize = rem + "px";
    }
    refreshRem();
    win.addEventListener("resize", function () {
      clearTimeout(tid); // 防止执行两次
      tid = setTimeout(refreshRem, 300);
    }, false);
    win.addEventListener("pageshow", function (e) {
      if (e.persisted) { // 浏览器后退的时候重新计算
        clearTimeout(tid);
        tid = setTimeout(refreshRem, 300);
      }
    }, false);
    if (doc.readyState === "complete") {
      doc.body.style.fontSize = "14px";
    } else {
      doc.addEventListener("DOMContentLoaded", function () {
        doc.body.style.fontSize = "14px";
      }, false);
    }
  })(750, 750);
  