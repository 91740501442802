import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/utils/rem";
//引入 hls,视频直播(m3u8)必须引入的
import "videojs-contrib-hls";
//播放rtmp视频
// import "videojs-flash";

import "@/style/Normalize.css";

import Element from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/style/element-variables.scss";
Vue.use(Element, {
  size: "small", // set element-ui default size
});

import Vant from "vant";
import "vant/lib/index.css";

Vue.use(Vant);

// 动画 animate.css
import animate from "animate.css";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
