import {
  setToken,
  getToken,
  removeToken,
  // setUserInfo,
  // getUserInfo,
} from "@/utils/auth";
const state = {
  token: getToken(),
  userInfo: {},
  isLogin: false,
};

const mutations = {
  login: (state, userDetail) => {
    state.userInfo = userDetail.userInfo;
    // setUserInfo(state.userInfo);
    state.token = userDetail.token;
    setToken(state.token);
  },
  logut: (state) => {
    state.userInfo = undefined;
    state.token = undefined;
    removeToken();
  },
  setUser: (state, data) => {
    state.userInfo = data;
    console.log(' state.userInfo', state.userInfo)
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
